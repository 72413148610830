.result-screen {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.result-screen h2 {
  margin-bottom: 20px;
}

.result-screen a {
  text-decoration: none;
  color: black;
}

.result-screen .tiles {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.result-screen .tile {
  background: #3498db;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
  width: 400px;
}

.result-screen img {
  width: 100%;
  max-height: 250px;
  object-fit: cover;
  overflow: hidden;
}

.result-screen .tile .footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.result-screen .tile .footer .left {
  padding-right: 20px;
}

.result-screen .tile .footer h3 {
  text-align: left;
  font-size: 16px;
  padding: 0;
  margin: 0;
}

.result-screen .tile .footer p {
  text-align: left;
  padding: 0;
  margin: 0;
}

.result-screen .tile .footer .rating {
  min-width: 100px;
}

.play-again-button {
  padding: 10px 20px;
  background-color: #3498db;
  color: white;
  border: none;
  cursor: pointer;
}

.star-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.star-icon.gold {
  color: gold;
}

@media (max-width: 600px) {
  .result-screen {
    padding: 10px;
  }

  .result-screen .tile {
    width: 100%;
    margin-bottom: 15px;
  }

  .result-screen .tile .footer {
    flex-direction: column;
    align-items: flex-start;
  }

  .result-screen .tile .footer .left {
    padding-right: 0;
    margin-bottom: 10px;
  }

  .result-screen .tile .footer .rating {
    min-width: auto;
  }

  .play-again-button {
    width: 100%;
    padding: 15px;
  }
}
