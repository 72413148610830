@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tektur:wght@400;600;700&display=swap');

body {
  font-family: 'Open Sans', sans-serif;
}

.App {
  text-align: center;
  padding: 20px;
}

.App-header {
  margin-bottom: 20px;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

button,
.button {
  background-color: lightblue;
  color: black;
  font-size: 32px;
  border: none;
  border-radius: 40px;
  padding: 20px;
  cursor: pointer;
  font-weight: bold;
  min-width: 400px;
  margin-bottom: 20px;
  text-decoration: none;
}

button:hover,
.button:hover {
  opacity: 0.5;
}

button:disabled {
  opacity: 0.5;
}

input[type="text"] {
  font-size: 32px;
  padding: 20px;
  border: 1px solid lightblue;
  border-radius: 40px;
  margin-bottom: 20px;
  width: 400px;
  text-align: center;
}

.survey-code {
  font-size: 36px;
  font-weight: bold;
  margin: 20px 0;
  padding-bottom: 20px;
  font-family: "Tektur", sans-serif;
}

.image-selection-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Responsive styles for smartphones */
@media (max-width: 600px) {
  button,
  .button {
    font-size: 24px;
    padding: 15px;
    min-width: 100%;
  }

  input[type="text"] {
    font-size: 24px;
    padding: 15px 0;
    width: 100%;
  }

  .survey-code {
    font-size: 28px;
  }
}