.image-selection {
  width: 100%;
  max-width: 400px;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-bottom: 20px;
}

.image-container {
  position: relative;
  cursor: pointer;
  aspect-ratio: 1;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.next-button {
  width: 100%;
  padding: 10px;
  background-color: #3498db;
  color: white;
  border: none;
  cursor: pointer;
}

.next-button:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
}

